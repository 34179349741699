import React, { useContext, useState } from "react";
import { LangContext } from "../../contexts/LangContext";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import HTMLReactParser from "html-react-parser";
import { sendEmail } from "../../utils/emailService";

const InfoEmail = () => {
  const { lang, toogleLang } = useContext(LangContext);
  const [email, setEmail] = useState("");
  const emailSubject = "Thanks for subscribing!";
  const emailBody = "Thank You So Much For Subscribing To Our Newsletter!";

  const phoneNumber = "628111427880";
  const text = "Halo PEACE. Saya ingin bertanya mengenai layanan...";
  const btnEmail = "psikoedu.peace@gmail.com";

  return (
    <div className="home-info">
      <div className="home-info-container">
        <div className="home-call-us">
          <div className="home-header-title">
            <CallIcon />
            <h1>{lang === "ENG" ? "Call Us" : "Hubungi Kami"}</h1>
          </div>
          <p>(021) 6323444</p>

          <div className="home-call-cta">
            <a
              href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURI(
                text
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              {lang === "ENG" ? "Whatsapp Our Admin" : "WhatsApp Admin Kami"}
            </a>

            <a href={`mailto:${btnEmail}`} target={"_top"}>
              {lang === "ENG" ? "Email Us Instead" : "Kirim email"}
            </a>
          </div>
        </div>

        <div className="home-location">
          <div className="home-header-title">
            <LocationOnIcon />
            <h1>{lang === "ENG" ? "Location" : "Lokasi"}</h1>
          </div>

          <p>
            Gedung E-Building Lt. 2 Komplek Harmoni Plaza, Blok I No 1-4 Jl.
            Suryopranoto no. 2, Jakarta Pusat RT.3/RW.8, Petojo Utara Gambir,
            Jakarta, 10160
          </p>
          <a
            className="home-btn-vision-us"
            href="https://goo.gl/maps/uFZYYkMyWiXprZJJ7"
            target="blank"
          >
            {lang === "ENG" ? "Visit Us" : "Kunjungi Kami"}
          </a>
        </div>

        <div className="home-opening-hours">
          <div className="home-header-title">
            <WatchLaterIcon />
            <h1>{lang === "ENG" ? "Opening Hours" : "Jam Operasional"}</h1>
          </div>
          <div className="home-opening-hours-section">
            <p>Mon - Fri</p>
            <p>09.00 - 17.00</p>
          </div>
          <div className="home-opening-hours-section">
            <p>Sat</p>
            <p>09.00 - 13.00</p>
          </div>
          <div className="home-opening-hours-section">
            <p>Sun</p>
            <p>Closed</p>
          </div>
        </div>
      </div>

      <div className="home-newsletter">
        <div className="overlay"></div>
        <img
          width="100%"
          src={process.env.PUBLIC_URL + "/BG Flowers.png"}
          alt="Background Flowers"
        />
        <h1>
          {lang === "ENG"
            ? HTMLReactParser(
                "Get The Latest Promotion By Subscribing <br/>To Our Newsletter"
              )
            : HTMLReactParser(
                "Dapatkan Promosi Terbaru Dengan <br/>Berlangganan Newsletter Kami"
              )}
        </h1>
        <input
          placeholder={lang === "ENG" ? "Your Email" : "Masukkan email"}
          value={email}
          onChange={(val) => {
            setEmail(val.target.value);
          }}
          type="text"
          name="email"
          id="email"
        />
        <button
          onClick={async () => {
            const response = await sendEmail(
              email,
              emailSubject,
              emailBody,
              undefined
            );
            if (response.status === 200) {
              setEmail("");
            }
          }}
          className="home-btn-subscribe"
        >
          {lang === "ENG" ? "Subscribe" : "Langganan"}
        </button>
      </div>
    </div>
  );
};

export default InfoEmail;
