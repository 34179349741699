import React, { useEffect } from "react";
import "../styles/Home.css";
import Banner from "../components/Home/Banner";
import Help from "../components/Home/Help";
import AhliCarousel from "../components/Home/AhliCarousel";
import Testimony from "../components/Home/Testimony";
import InfoEmail from "../components/Home/InfoEmail";
import Instagram from "../components/Home/Instagram";
import Footer from "../components/Home/Footer";
import useWindowSize from "../customs/useWindowSize";
import { useState } from "react";
import MobileInfoEmail from "../components/Home/Mobile/MobileInfoEmail";
import Whatsapp from "../components/Whatsapp";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Helmet } from "react-helmet";

const Home = () => {
  const socialMediaData = [
    {
      icon: <InstagramIcon className="icon-social-media" />,
      url: "https://www.instagram.com/peace.idn",
      alias: "instagram.com/peace.idn",
    },
    {
      icon: <EmailIcon className="icon-social-media" />,
      url: "mailto:psikoedu.peace@gmail.com ",
      alias: "psikoedu.peace@gmail.com",
    },
    {
      icon: <FacebookIcon className="icon-social-media" />,
      url: "https://www.facebook.com/peace.idn",
      alias: "facebook.com/peace.idn",
    },
    {
      icon: <YouTubeIcon className="icon-social-media" />,
      url: "https://www.youtube.com/channel/UCu2WqhxLel5i9FUyS1h1-6A",
      alias: "Youtube PEACE.IDN",
    },
    {
      icon: <LinkedInIcon className="icon-social-media" />,
      url: "https://www.linkedin.com/company/psychoeducationalassessmentcenterofexcellence/people/",
      alias: "LinkedIn PEACE.IDN",
    },
  ];

  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(size.width < 992);

  useEffect(() => {
    setIsMobile(size.width < 992);
  }, [size]);

  return (
    <div>
      <Helmet>
        <title>Home | Peace Website</title>
      </Helmet>

      <Banner />
      <Help />
      <AhliCarousel />
      {!isMobile && <Testimony />}
      {!isMobile ? <InfoEmail /> : <MobileInfoEmail />}
      {!isMobile && (
        <Instagram
          data={socialMediaData}
          dataSrc={"23218bfd-dfc2-46b5-a6ad-e98c6c2eb0b8#instagram"}
        />
      )}
      <Footer />
      <Whatsapp />
    </div>
  );
};

export default Home;
