import React, { useContext, useEffect, useState } from "react";
import "../styles/Team.css";
import Banner from "../components/Team/Banner";
import FilterTag from "../components/Services/FilterTag";
import { FilterContext } from "../contexts/FilterContext";
import { LangContext } from "../contexts/LangContext";
import Footer from "../components/Home/Footer";
import parser from "html-react-parser";
import useWindowSize from "../customs/useWindowSize";
import { Helmet } from "react-helmet";

const filterTags = {
  ENG: [
    "ALL",
    "PSYCHOEDUCATIONAL ASSESSMENT",
    "ABA THERAPY / APPLIED BEHAVIOUR ANALYSIS",
    "PLAY AND CREATIVE ART THERAPY",
    "FILIAL PLAY",
    "GROUP AND INDIVIDUAL COUNSELING",
    "SPEECH AND LANGUAGE THERAPY",
    "LEARNING SUPPORT",
    "SOCIAL SKILL SERVICES",
    "OCCUPATIONAL THERAPY AND SENSORY INTEGRATION",
  ],
  IDN: [
    "SEMUA",
    "ASESMEN PSIKOEDUKASI",
    "SOCIAL SKILLS SERVICES",
    "KONSELING INDIVIDU DAN KELOMPOK",
    "TERAPI ABA / APPLIED BEHAVIOUR ANALYSIS",
    "TERAPI WICARA",
    "LEARNING SUPPORT",
    "FILIAL PLAY",
    "PLAY AND CREATIVE ART THERAPY",
    "TERAPI OKUPASI DAN SENSORI INTEGRASI",
  ],
};

const DataTeams = [
  {
    img: "team1.png",
    role: [
      "PLAY AND CREATIVE ART THERAPY",
      "GROUP AND INDIVIDUAL COUNSELING",
      "KONSELING INDIVIDU DAN KELOMPOK",
    ],
    name: "Dr. Alice Arianto, Psy. D, CGP, FPCM",
    titleENG: "Certified Play & Creative Art Therapist<br/>Clinical Supervisor",
    titleIDN: "Certified Play & Creative Art Therapist<br/>Clinical Supervisor",
  },
  {
    img: "team2.png",
    role: [
      "PSYCHOEDUCATIONAL ASSESSMENT",
      "ASESMEN PSIKOEDUKASI",
      "PLAY AND CREATIVE ART THERAPY",
    ],
    name: "Kurnia Mega, M.Psi, Psikolog",
    titleENG:
      "Clinical Psychologist<br/>Certified Play & Creative Art Therapist",
    titleIDN: "Psikolog dan Terapis Bermain",
  },
  {
    img: "team3.png",
    role: ["PLAY AND CREATIVE ART THERAPY", "FILIAL PLAY"],
    name: "Merry Sadan, S.E.",
    titleENG:
      "Center Coordinator<br>Certified Play & Creative Art Therapist<br>Filial Play Coach Mentor",
    titleIDN:
      "Center Coordinator<br>Certified Play & Creative Art Therapist<br>Filial Play Coach Mentor",
  },
  {
    img: "team4.png",
    role: ["LEARNING SUPPORT", "SOCIAL SKILL SERVICES"],
    name: "Angelia Kristanti Permana, M. Ed",
    titleENG: "Educational Consultant",
    titleIDN: "Konsultan Edukasi",
  },
  {
    img: "team5.png",
    role: [
      "PLAY AND CREATIVE ART THERAPY",
      "LEARNING SUPPORT",
      "SOCIAL SKILL SERVICES",
    ],
    name: "Amabelle Vanalyssa , M.A",
    titleENG:
      "Creative Art & Inclusive Education Specialist<br>Certified Play Therapist",
    titleIDN:
      "Creative Art & Inclusive Education Specialist<br>Certified Play Therapist",
  },
  {
    img: "team6.png",
    role: ["SPEECH AND LANGUAGE THERAPY", "TERAPI WICARA"],
    name: "Marie Jorie Bautista, SLP ",
    titleENG: "Speech & Language Pathologist",
    titleIDN: "Ahli Patologi Bicara dan Bahasa",
  },
  {
    img: "team7.png",
    role: ["LEARNING SUPPORT"],
    name: "Weni Mardi Waluyani, M. Ed.",
    titleENG: "Special & Gifted Education Consultant",
    titleIDN: "Konsultan Pendidikan Khusus dan Berbakat",
  },
  {
    img: "team8.png",
    role: ["PLAY AND CREATIVE ART THERAPY", "FILIAL PLAY"],
    name: "Bunga Tiku Masakke, S.Psi",
    titleENG:
      "Certified Play & Creative Art Therapist<br/>Filial Play Coach Mentor",
    titleIDN:
      "Certified Play & Creative Art Therapist<br/>Filial Play Coach Mentor",
  },
  {
    img: "team18.png",
    role: [
      "ABA THERAPY / APPLIED BEHAVIOUR ANALYSIS",
      "TERAPI ABA / APPLIED BEHAVIOUR ANALYSIS",
    ],
    name: "Raymond Marcel Semaun, M.A., CAS.",
    titleENG: "Senior ABA Consultant",
    titleIDN: "Konsultan Senior ABA",
  },
  {
    img: "team24.png",
    role: ["PSYCHOEDUCATIONAL ASSESSMENT", "ASESMEN PSIKOEDUKASI"],
    name: "Deden Mulyadi, M.Psi., Psikolog",
    titleENG: "Associate Psychologist",
    titleIDN: "Associate Psychologist",
  },
  {
    img: "team23.png",
    role: ["OCCUPATIONAL THERAPY AND SENSORY INTEGRATION"],
    name: "Sheryl Gurban",
    titleENG:
      "Occupational Therapist Certified<br/>Ayre’s Sensory Integration<br/>(ASI) Practioner",
    titleIDN:
      "Occupational Therapist Certified<br/>Ayre’s Sensory Integration<br/>(ASI) Practioner",
  },
  {
    img: "team25.png",
    role: ["PSYCHOEDUCATIONAL ASSESSMENT", "ASESMEN PSIKOEDUKASI"],
    name: "Felicia, M.Psi., Psikolog, CGA",
    titleENG: "Associate Psychologist",
    titleIDN: "Associate Psychologist",
  },
  {
    img: "team9.png",
    role: [
      "ABA THERAPY / APPLIED BEHAVIOUR ANALYSIS",
      "TERAPI ABA / APPLIED BEHAVIOUR ANALYSIS",
    ],
    name: "Suryanto",
    titleENG: "ABA Junior Consultant",
    titleIDN: "Konsultan Junior ABA",
  },
  {
    img: "team11.png",
    role: [
      "ABA THERAPY / APPLIED BEHAVIOUR ANALYSIS",
      "TERAPI ABA / APPLIED BEHAVIOUR ANALYSIS",
    ],
    name: "Oktaviani Syarif, S. Psi.",
    titleENG: "ABA Therapist",
    titleIDN: "Terapis ABA",
  },
  {
    img: "team10.png",
    role: ["SPEECH AND LANGUAGE THERAPY", "TERAPI WICARA"],
    name: "Bangkit Pratama",
    titleENG: "Speech Therapist",
    titleIDN: "Terapis Wicara",
  },
  {
    img: "team13.png",
    role: ["PLAY AND CREATIVE ART THERAPY", "FILIAL PLAY"],
    name: "Yudi Hartanto",
    titleENG:
      "Certified Play & Creative Art Therapist<br/>Filial Play Coach Mentor",
    titleIDN:
      "Certified Play & Creative Art Therapist<br/>Filial Play Coach Mentor",
  },
  {
    img: "team14.png",
    role: [
      "ABA THERAPY / APPLIED BEHAVIOUR ANALYSIS",
      "TERAPI ABA / APPLIED BEHAVIOUR ANALYSIS",
    ],
    name: "Beny Hidayat",
    titleENG: "ABA Therapist",
    titleIDN: "Terapis ABA",
  },
  {
    img: "team15.png",
    role: [
      "ABA THERAPY / APPLIED BEHAVIOUR ANALYSIS",
      "TERAPI ABA / APPLIED BEHAVIOUR ANALYSIS",
    ],
    name: "Suroto",
    titleENG: "ABA Therapist",
    titleIDN: "Terapis ABA",
  },

  {
    img: "team16.png",
    role: [],
    name: "Dwi Handayani",
    titleENG: "Administrator",
    titleIDN: "Administrasi",
  },
  {
    img: "team22.png",
    role: [
      "GROUP AND INDIVIDUAL COUNSELING",
      "KONSELING INDIVIDU DAN KELOMPOK",
    ],
    name: "Harlem & Vania Salim",
    titleENG: "Couple Counsellor",
    titleIDN: "Konselor Pasangan",
  },
  {
    img: "team21.png",
    role: ["SPEECH AND LANGUAGE THERAPY", "TERAPI WICARA"],
    name: "Yulidar A.Md. TW., M.Pd",
    titleENG: "Speech Therapist",
    titleIDN: "Terapis Wicara",
  },
  {
    img: "team17.png",
    role: ["SOCIAL SKILL SERVICES"],
    name: "Nurfitriani Djaafara, B.Sc.",
    titleENG: "The Social Club Facilitator",
    titleIDN: "Fasilitator The Social Club",
  },
  {
    img: "team19.png",
    role: ["FILIAL PLAY", "PLAY AND CREATIVE ART THERAPY"],
    name: "Lily Salim",
    titleENG:
      "Certified Play & Creative Art Therapist<br/>Filial Play Coach Mentor",
    titleIDN:
      "Certified Play & Creative Art Therapist<br/>Filial Play Coach Mentor",
  },
  {
    img: "team20.png",
    role: [
      "ABA THERAPY / APPLIED BEHAVIOUR ANALYSIS",
      "TERAPI ABA / APPLIED BEHAVIOUR ANALYSIS",
    ],
    name: "Sasabela Abdun Vyandri, S.Psi.",
    titleENG: "ABA Therapist",
    titleIDN: "Terapis ABA",
  },
];

const OurTeam = () => {
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(size.width < 992);
  const { filter, changeFilter } = useContext(FilterContext);
  const [data, setData] = useState(DataTeams);
  const { lang, toogleLang } = useContext(LangContext);

  useEffect(() => {
    setIsMobile(size.width < 992);
  }, [size]);

  useEffect(() => {
    const newData = DataTeams.filter((val) => val.role.includes(filter));
    if (filter === "ALL" || filter === "SEMUA") {
      setData(DataTeams);
    } else {
      setData(newData);
    }

    console.log(filter, lang);
  }, [filter]);

  useEffect(() => {
    lang === "ENG" ? changeFilter("ALL") : changeFilter("SEMUA");
  }, [lang]);

  return (
    <div className="our-team-container-page">
      <Helmet>
        <title>Our Team | Peace Website</title>
      </Helmet>

      <Banner />

      {!isMobile && (
        <div className="team-container">
          <div className="team-filter-container">
            {lang === "ENG"
              ? filterTags.ENG.map((data, index) => (
                  <FilterTag
                    key={index}
                    tag={data}
                    isActive={filter === data}
                  />
                ))
              : filterTags.IDN.map((data, index) => (
                  <FilterTag
                    key={index}
                    tag={data}
                    isActive={filter === data}
                  />
                ))}
          </div>
        </div>
      )}

      <div className="team-info-container">
        {data.map((val, index) => (
          <div key={index} className="team-info-card">
            <div className="team-info-img-container">
              <img
                className="team-info-img"
                src={process.env.PUBLIC_URL + "/team/" + val.img}
                alt="Image team"
              />
            </div>
            <div className="team-info-card-detail">
              <h1 className="team-info-card-title">{val.name}</h1>
              {lang === "ENG" ? (
                <p className="team-info-card-desc">{parser(val.titleENG)} </p>
              ) : (
                <p className="team-info-card-desc">{parser(val.titleIDN)} </p>
              )}
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default OurTeam;
