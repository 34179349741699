import { WhatsApp } from "@mui/icons-material";
import React from "react";
import "../styles/whatsapp.css";

const Whatsapp = () => {
  const phoneNumber = "628111427880";
  const text = "Halo PEACE. Saya ingin bertanya mengenai layanan...";
  return (
    <a
      href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURI(
        text
      )}`}
      target="_blank"
      rel="noreferrer"
    >
      <div className="whatsapp-plugin-button">
        <WhatsApp className="whatsapp-plugin-icon" />
      </div>
    </a>
  );
};

export default Whatsapp;
